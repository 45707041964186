<template>
  <div class="goods-wrap">
    <webview src="https://m.kuaidi100.com/help/contraband.html"></webview>
  </div>
</template>
<script>
export default {
  name: "Cargo"
  // beforeRouteEnter (to, from, next) {
  //   if(!to.params.source) {
  //     next(vm => {
  //       vm.navigator.replace({
  //         name: "courier"
  //       })
  //     })
  //   } else {
  //     next()
  //   }
  // }
}
</script>
<style scoped>
.goods-wrap {
  height: 100%;
  background: #FFF;
}
</style>