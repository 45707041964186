/* eslint-disable no-console */
import util from "lib/util"

export default {
  name: 'normal',
  data(){
    return {
      internation: ['dhl', 'ups', 'fedex', 'tnt'],
      internalReg: /德邦快递|天地华宇|百世物流/,
      marketData: {},
      remarks: [],
      tradeTime: {
        show: false,
        tips: ''
      },
      payType: {
        "MONTHLY": "月结",
        "SHIPPER": "寄付",
        "CONSIGNEE": "到付"
      },
      islogin: false,
      auth: this.$store.state.userAuth,
      doAuth: false,
      showHeavy: util.query("heavy") == 1 &&  !util.getStorage("showHeavy") && !this.$store.state.globalData.isWechat,
      showDispatch: false,
      waitSubmit: false,
      order: {
        rec: {
          xzqName: '',
        },
        send: {
          xzqName: '',
        },
        payment: 'SHIPPER',
        sentunit: {show:false, value:'PERSONAL'},
        valins: {check: 'novalin', value: '',show: false},
        gotaddr: '',
        count: '',
        weight: '',
        comment:"",
        cargo: '',
        department: '',
        payaccount: '',
        companys: [],
        companyText: '',
        payAccountLoad: false,
        ordering: false
      },
      selectCom: {},
      dialog: {
        show: false,
        type: '',
        transform: '100%'
      },
      popup: {
        show: false,
        type: ''
      },
      singleType: util.query("comtype") == 'single',
      commentExm: [{lable:'按寄件地址来取件'},{lable:'到了打电话'},{lable:'不方便接电话'},{lable:'缺少包装'},{lable:'上午来取件'},{lable:'下午来取件'}],
      cargos: {
        type: 0,
        normal: ['文件', '生活用品', '电子产品', '服饰', '食品'],
        custom: util.getStorage('customCargo') || []
      },
      cargoTp: {},
      showContraband: false,
      showComment: false,
      showValins: false
    }
  },
  methods: {
    goCourier() {
      this.navigator.switch({
        name: "courier"
      })
    },
    getMarketBySign(sign) {
      this.loading = this.$loading("正在加载...")
      this.getRemarks()
      this.$http.get('/apicenter/kdmkt.do?method=getMktInfo&type=MKTINFO', {
        data: {
          sign: sign,
          latitude: this.curentLat,
          longitude: this.currentLng
        }
      }).then(result => {
        this.loading.hide()
        this.contactInit()
        if (result.data) {
          this.selectMarket(result.data)
        } else {
          this.goCourier()
        }
      }).catch(() => {
        this.loading.hide()
        this.goCourier()
      })
    },
    getRemarks(){
      this.$http.get("/mobile/mobileapi.do?method=courierLeaMsgList", {
        data: {
          coname: this.$store.state.globalData.coname || 'kuaidi100'
        },
        handleFail: false
      }).then(r => {
        if(r.data && r.data.length) {
          this.remarks = r.data
          for(let i = 0; i < r.data.length; i++) {
            r.data[i].isSelected && this.catComment(r.data[i].lable)
          }
        } else {
          this.remarks = this.commentExm
        }
      }).catch(() => {
        this.remarks = this.commentExm
      })
    },
    getGoods(type) {
      this.$http.get("/mobile/mobileapi.do?method=goodsList",{
        data: {
          bussType: type
        }
      }).then(result => {
        this.cargos.normal = []
        result.data.map(item => {
          this.cargos.normal.push(item.name)
        })
      })
    },
    selectMarket(data) {//由于该页面后续会加入快递员选择的逻辑，故要分离出该方法
      data.customInner = true //用来标记是否显示取件地址
      this.marketData = data
      this.calcTradeTime()
      this.showCompany()
      if(data.roletype == "THRID" && util.getSession("supportDispatch") == 1) {
        this.showDispatch = true
      }
      if(data.unact) {
        this.unactConfirm = this.$confirm({
          content: "该快递员已经很久没有活跃了，请谨慎下单。",
          cancelText: "继续下单",
          confirmText: "返回列表"
        },() => {
          this.goCourier()
        })
      }
    },
    calcTradeTime() {
      var now = new Date()
      var hour = now.getHours()
      var minute = now.getMinutes()
      var timeList = this.marketData.serviceTime ? this.marketData.serviceTime.split('-') : ''
      if (timeList && timeList[1]) {
        var begin = timeList[0].split(':'),
          end = timeList[1].split(':')
        var beginHour = parseInt(begin[0]),
          beginMinute = parseInt(begin[1])
        var endHour = parseInt(end[0]),
          endMinute = parseInt(end[1])
        if (hour > endHour || hour == endHour && minute > endMinute) { //下班时间
          this.tradeTime.show = true
          this.tradeTime.tips = '该快递员营业时间为<strong>' + this.marketData.serviceTime + '</strong>，现在下单预计明天的<strong>' + timeList[0] + '</strong>后才能上门取件！';
        } else if (hour < beginHour || hour == beginHour && minute < beginMinute) { //上班前
          this.tradeTime.show = true
          this.tradeTime.tips = '该快递员营业时间为<strong>' + this.marketData.serviceTime + '</strong>，现在下单预计<strong>' + timeList[0] + '</strong>后才能上门取件！'
        } else if (endHour * 60 + endMinute - (hour * 60 + minute) < 30) { //下班前半小时
          this.tradeTime.tips = '该快递员<strong>' + timeList[1] + '</strong>下班，可能无法及时上门服务，如有急件，请先联系确认后再下单';
          this.tradeTime.showPhone = true
          this.tradeTime.show = true
        } else {
          this.tradeTime.tips = ''
          this.tradeTime.show = false
        }
      } else {
        this.tradeTime.tips = ''
        this.tradeTime.show = false
      }
    },
    showCompany() {
      var hasCom = false,
        hasLast = false,
        comIndex = 0,
        lastIndex = 0
      var comDataList = this.marketData.comlist2
      if (this.singleType) { //第三方不展示快递公司的需求
        return this.selectCompany(0)
      }
      this.marketData.comkeys = {} //索引优化用
      try{
        for (let j = 0, len = comDataList.length; j < len; j++) {
          var com = comDataList[j].kuaidiCom ;
          (this.marketData.comkeys[com] || (this.marketData.comkeys[com] = [])).push(comDataList[j]) //遍历优化用
          if (this.order.company == com) { //判断是否包含当前已选快递公司
            hasCom = true
            comIndex = j
          }
          if (com == util.getStorage('kd_sentcom') && comDataList[j].servicetype == util.getStorage("kd_servicetype")) {
            hasLast = true
            lastIndex = j
          }
        }
        if (hasCom) {
          this.selectCompany(comIndex)
        } else if (hasLast) {
          this.selectCompany(lastIndex)
        } else {
          this.marketData.comlist2.length == 1 && this.selectCompany(0)
        }
        this.getPrice()
      } catch(e) {
        console.log(e)
      }
    },
    selectCompany(index) {
      if (this.marketData.comlist2 && typeof this.marketData.comlist2[index] != 'undefined') {
        this.selectCom = this.marketData.comlist2[index]
        if (this.marketData.type != 'MARKET' && typeof this.selectCom.field != undefined && +this.selectCom.field < 1 && this.order.valins.value) {
          this.$confirm({
            content: '<p class="p-dot">抱歉！该快递员不支持该快递公司在线保价服务，如需保价，请与快递员协商。</p><p class="p-dot">为保障您的权益，线下保价时请确认快递单上的保价项目正确填写。</p>',
            type: 'alert'
          })
        }
        this.order.company = this.selectCom.kuaidiCom
        this.order.servicetype = this.selectCom.servicetype || '标准快递'
      } else {
        this.order.company = ""
        this.order.servicetype = ''
      }
      this.checkCompany(this.selectCom)
    },
    doSelectCompany(index) {
      if(this.popup.type == 'comlist2') return
      this.selectCompany(index)
      history.back()
    },
    checkCompany(com) { //检测重货和国际物流
      if (com.kuaidiCom) {
        if (this.internation.indexOf(com.kuaidiCom) != -1) { //国际快递
          this.$confirm({
            content: '国际快递时效价格与多因素有关，下单前请联系快递员了解详情',
            confirmText: '联系快递员',
            cancelText: '继续下单'
          }, () => {
            location.href = 'tel:' + this.marketData.phone
          })
        } else if (this.internalReg.test(com.fullname)) { //国内物流
          this.$confirm({
            content: '您当前选择的是物流服务，适合重货和大货，时效价格都与快递不同，下单前请联系快递员了解详情。',
            confirmText: '联系快递员',
            cancelText: '继续下单'
          }, () => {
            location.href = 'tel:' + this.marketData.phone;
          })
        }
      }
    },
    setActionCache(type) {
      let cache = this.cache = this.cache || {}
      let order = this.order
      switch (type) {
        case 'showCargo':
          cache.cargo =  Object.assign({}, order.cargo)
          cache.otherCargo = order.otherCargo
          cache.cargoInclude = order.cargoInclude.slice(0)
          break
        case 'showValins': 
          cache.valins = Object.assign({}, order.valins)
          break
        case 'showComment':
          cache.comment = order.comment
          break
        case 'showTime':
          cache.selectDaytime = Object.assign({}, this.selectDaytime)
          break
        case 'showComlist':
          cache.company = Object.assign({}, order.company)
          break
      }
    },
    resetAction(type) {
      let cache = this.cache = this.cache || {}
      let order = this.order
      switch (type) {
        case 'cargo':
          order.cargo = cache.cargo
          order.otherCaogo =  cache.otherCargo
          order.cargoInclude = cache.cargoInclude
          break
        case 'valins': 
          order.valins = cache.valins
          break
        case 'comment':
          order.comment = cache.comment
          break
        case 'time':
          this.selectDaytime = cache.selectDaytime
          break
        case 'comlist':
          order.company = cache.company
          break
      }
    },
    showAction(type) {
      this.setActionCache(type)
      this[type] = true
    },
    actionConfirm(type, $event) {
      if (type == 'cargo') {
        if (this.order.cargo.type == "other" && !this.order.otherCargo || !this.order.cargo.name) return this.$toast("请输入物品名称", 1000)
      }  else if(type === 'valins' && this.order.valins.value) {
        if(this.order.valins.value % 100) return this.$toast("请输入100的整倍数")
        if(!this.order.valins.agree) return this.$toast("请阅读并同意《快递100平台保价增值服务协议》")
      }
      if (type == 'prepay') {
        return this.navigator.push({
          name: "dispatchPrepay",
          query: {
            expid: this.orderDetail.expId,
            dispatchid: this.order.market.dispatchid
          }
        })
      }
      if (type == 'cargo') {
        this.getCompany()
        this.getPrice()
        this.getExpressBrand()
      }
      $event.hide()
    },
    toWebview(name) {
      this.navigator.push({
        name: name,
        params: {
          source: "normal"
        }
      })
    },
    doValins() {
      if(this.order.valins.check == 'valin'){
        if(!/^\d+(?:\.\d+)?$/.test(this.order.valins.value)){
          return this.$toast('请输入正确的保价')
        } else if(this.order.valins.value > 100000){
          return this.$toast('保价不能超过10万')
        } else {
          history.back()
        }
      } else {
        this.order.valins.value = ''
        history.back()
      }
    },
    doSentunit(){
      if(this.order.sentunit.value == 'COMPANY') {
        if(!this.order.payaccount) {
          return this.$toast('请输入正确的所在公司名称')
        }
        if(!this.order.department) {
          return this.$toast("请输入费用所属部门")
        }
        history.back()
      } else {
        history.back()
      }
    },
    getPrice() { //获取邮费
      if (this.singleType) return //第三方定制不展示快递公司
      if (!(this.order.send && this.order.send.xzqName) || !(this.order.rec && this.order.rec.xzqName)) return
      this.$http.get("/apicenter/order.do?method=availableCom", {
        data: {
          sign: this.marketData.sign,
          startxzq: this.order.send.xzqName.split(',').join(''),
          toxzq: this.order.rec.xzqName.split(',').join(''),
          weight: 0.5,
          sendAddr: this.order.rec.xzqName.split(",").join('') + this.order.rec.addresss
        },
        handleFail: false
      }).then(res => {
        var comKey = [], data = res.data, com = null
        for (let i in data) {
          com = data[i]
          comKey = this.marketData.comkeys[com.kuaidiCom] || []
          if (!com.firstprice) continue
          for (let j = 0; j < comKey.length; j++) {
            if ((comKey[j].servicetype || '').indexOf(com.service) == -1) continue
            this.$set(comKey[j], 'price', "首" + com.firstprice + '元，续' + com.overpriceunit + '元/1.0kg' + (com.totalAvg ? '，约' + com.totalAvg + '天' : ''))
          }
        }
      }).catch(() => {

      })
    },
    contactInit() { //获取寄件地址并设置上次地址
      try{
        if(!util.query("sendAgain")) return 
        let address = util.getSession("reOrderAddress")
        if (address) {
          address.rec && this.selectContact(address.rec, 'rec')
          address.send && this.selectContact(address.send, 'send')
        }
        //app 嵌入场景
        var shareAddr = util.getSession('shareAddr')
        if (shareAddr && shareAddr.name) {
          this.selectContact(shareAddr,  'rec')
        }
      } catch(e){
        console.log(e)
      }
     
    },
    selectContact (data, type) {
      if (!data) return 
      if(data.address.length < 4) {
        return this.$toast("详细地址不能少于4个字")
      }
      this.lastaddress = undefined
      console.log(this.order)
      this.order[type] = data
      this.getPrice()
      if (type == 'send') {
        this.checkInner()
      }
    },
    showContact(type) {
      if(this.kdnum && type === 'rec') return
      if (!this.islogin) {
        return this.navigator.push({
          name: "login"
        })
      }
      this.broadcast() // 接收地址填充的通知
      this.navigator.push({
        name: "addressList",
        params: {
          source: "normal"
        },
        query: {
          from: "normal",
          tag: type,
          selectId: this.order[type].id || -1
        }
      })
    },
    broadcast() {
      this.$broadcastChannel.$on("fillContact", (addr, type) => {
        this.selectContact(addr, type)
        this.$broadcastChannel.$off("fillContact")
      })
    },
    showSelectContact(type) {
      if (!this.islogin) {
        return this.navigator.push({
          name: "login"
        })
      }
      this.broadcast()
      this.navigator.push({
        name: "addressDetail",
        params: {
          detail: Object.assign({}, this.order[type]),
          source: "normal",
          tag: type
        }
      })
    },
    checkInner() {
      var city = this.order.send.name ? this.order.send.city || this.order.send.xzqName.split(',')[1] : ''
      if(this.marketData.roletype == "THRID") return;
      if (city) { //已经填写了地址
        if (city.indexOf(this.marketData.city) == -1 && this.marketData.city.indexOf(city) == -1) { //寄件地址与快递员不在同一个城市
          this.$confirm({
            content: '所填寄出地址与快递员不在同一城市，请检查填写是否正确<p>快递员所在地：' + this.marketData.city + '</p><p>你的寄件地：' + city + '</p>',
            confirmText: '继续下单',
            cancelText: '去修改',
          }, null, () => {
            this.showSelectContact('send')
          });
          this.marketData.customInner = false
        } else {
          // this.$http.axios.get('https://restapi.amap.com/v3/geocode/geo',{
          this.$http.axios.get('/third/api/gaodeform',{
            params: {
              address: this.order.send.xzqName.split(',').join('') + this.order.send.address,
              city: city,
              // key: '77d5e24ecae26447c607d3a152b3ddf5',
              path: 'v3/geocode/geo',
              pd: 'SELF',
              client: 'M',
              ref: '/express/normal/indexx',
              jscode: '6be829187e17a2d6466b65225ea4c131',
              s: 'rsv3'
            },
            emptyUser: true
          }).then(result => {
            var location = []
            result = result.data.data
            if (result.geocodes.length && result.geocodes[0].location) {
              location = result.geocodes[0].location.split(',')
            } else {
              location = [this.currentLng, this.currentLat]
            }
            if (location[0] && location[1]) {
              this.$http.post('/apicenter/kdmkt.do?method=checkinner', {
                data: {
                  sign: this.marketData.sign,
                  optor: '',
                  latitude: location[1],
                  longitude: location[0],
                  token: this.$store.state.globalData.token,
                  ltype: 'GCJ02'
                }
              }).then(result => {
                this.localInner = result.data == 'Y'
                if (!this.localInner) { //取件范围外
                  this.marketData.customInner = false
                  this.$confirm({
                    content: '您的地址不在上门服务范围内，继续下单需要您自行联系快递员确定取件方式',
                    confirmText: '继续下单',
                    cancelText: '联系快递员'
                  }, null, () => {
                    window.location.href = 'tel:' + this.marketData.phone
                  })
                } else {
                  this.marketData.visitService && (this.marketData.customInner = true)
                }
              })
            }
          })
        }
      }
    },
    showPopup(type) {
      this.popup.type = type
      this.popup.show = true
      history.pushState({title: type}, null)
    },
    getAccount() {
      this.order.payaccount = ''
      this.loading = this.$loading("正在获取公司信息...")
      if (!this.order.companyText) {
        this.order.companys = []
        this.order.payaccount = ''
        return this.loading.hide()
      }
      this.$http.get('/order/open/exclusiveVisit.do', {
        data: {
          type: 'MKTFEELIST',
          sign: this.marketData.sign ? this.marketData.sign : "kuaidiMarket",
          name: this.order.companyText
        },
        handleLogin: this.$store.state.globalData.token ? false : ''
      }).then(result => {
        this.loading.hide()
        if(result.data){
          this.order.companys = result.data
        }
      }).catch(() => {
        this.loading.hide()
        this.$toast("公司信息获取失败")
      })
    },
    hideAccount(){
      setTimeout(() => {
        this.order.companys = []
      }, 0)
    },
    selectAccount(item){
      this.order.payaccount = item.payaccount
      this.order.companyText = item.name
      this.order.companys = []
    },
    validateOrder(){
      var word,returnValue = false
      var orderInfo = this.order
      if (!orderInfo.send.name) {
        this.$toast("请先选择寄件人")
      } else if (!orderInfo.rec.name) {
        this.$toast("请先选择收件人")
      } else if (orderInfo.send.name.length <= 1 && this.marketData.type == 'MARKET') {
        this.$toast("根据国家有关政策法规，请填写寄件人真实姓名（姓名不能为一个字），如有疑问请联系客服：400-000-0387")
      } else if (orderInfo.send.name.length > 5 && this.marketData.type == 'MARKET') {
        this.$toast("根据国家有关政策法规，请填写寄件人真实姓名（姓名不能超过5个字），如有疑问请联系客服：400-000-0387")
        } else if (word = orderInfo.send.name.match(/先生|小姐|女士|总|经理/) && this.marketData.type == 'MARKET'){//eslint-disable-line
        this.$toast("根据国家有关政策法规，请填写寄件人真实姓名（姓名不能包含“" + word[0] + "”字眼），如有疑问请联系客服：400-000-0387")
      } else if (orderInfo.send.name.match(/\w/) && this.marketData.type == 'MARKET') {
        this.$toast("根据国家有关政策法规，请填写寄件人真实姓名（请勿使用非中文字符），如有疑问请联系客服：400-000-0387")
      } else if (this.marketData.type == 'MARKET' && orderInfo.send.name == orderInfo.rec.name && orderInfo.send.phone == orderInfo.rec.phone && orderInfo.send.address == orderInfo.rec.address) {
        this.$toast("收寄件人不能相同")
      } else if (!orderInfo.company) {
        this.$toast("请先选择快递公司")
      } else if (!orderInfo.sentunit) {
        this.$toast("请先选择寄个人件或公司件")
      } else if (orderInfo.gotaddr.length > 60) {
        this.$toast("取件地址字数超长")
      } else if(!orderInfo.cargo){
        this.$toast("请填写物品信息");
      } else if (orderInfo.sentunit == "COMPANY") {
        if (!orderInfo.payaccount) {
          this.$toast("请输入正确的所在公司名称")
        } else if (!orderInfo.department) {
          this.$toast("请输入费用所属部门")
        } else if (orderInfo.department.length > 60) {
          this.$toast("费用部门字数超长")
        } else {
          returnValue = true
        }
      } else {
        returnValue = true
      }
      return returnValue
    },
    submit () {
      if(this.order.ordering) return
      if(!this.validateOrder()) return
      /**实名认证逻辑后置到订单详情
      if(!this.auth && !this.login2submit) {
        this.doAuth = true
        this.$confirm({
          content: "依据国家邮政管理局要求，寄件需要实名登记。登记后，再次寄件无需重复登记。",
          confirmText: "立即实名",
          cancelText: this.isShenzhen ? "取消" : "暂不实名"
        }, () => {
          this.waitSubmit = true
          this.goAuth()
        }, () => {
          if(this.isShenzhen) {
            this.waitSubmit = false
            this.doAuth = false
          } else {
            this.login2submit = true
            if(this.$store.state.globalData.token) {
              this.submit()
            } else {
              this.navigator.push({
                name: "login"
              })
              this.waitSubmit = true
            }
          }
        })
        return
      }
       */
      this.loading = this.$loading("正在提交，请稍后...")
      var order = this.order
      var submitData =  {
          type: "MKTSENT",
          sign: this.marketData.sign,
          joinSign: this.marketData.joinSign,
          sendName: order.send.name,
          sendMobile: order.send.phone || order.send.fixedPhone,
          sendAddr: order.send.address,
          sendxzq: order.send.xzqName,
          recName: order.rec.name,
          recMobile: order.rec.phone || order.rec.fixedPhone,
          recAddr: order.rec.address,
          recxzq:order.rec.xzqName,
          com: order.company,
          servicetype: order.servicetype,
          sentunit: order.sentunit.value,
          payment: order.payment,
          payaccount: order.payaccount,
          department: order.department,
          cargo: order.cargo,
          valins: order.valins.value,
          priceTimeInfo: this.selectCom.price || '',
          gotaddr: order.gotaddr,
          weight: order.weight,
          comment: order.comment,
          count:order.count,
          longitude: this.currentLng,
          latitude: this.currentLat,
          orderSource: (util.query("orderSource") || this.$store.state.globalData.coname || this.$store.state.globalData.fromClient ||  'm') + (util.query('sendAgain') == 'do' ? "_sendAgain" : ""),
          stampid: util.query('stampid') || '',
          siid: util.query("siid") || '',
          linkSource: util.query("linkSource") || '',
          optor: util.query("optor") || '',
          ref: document.referrer,
          ua: navigator.userAgent.toLowerCase()
        }, url = '/apicenter/order.do?method=msubmitOrder'
      if(order.rec.id && order.rec.id.indexOf('local_') == -1){//需要标记地址
        submitData.raddrid = order.rec.id
      }
      if(order.send.id && order.send.id.indexOf('local_') == -1){//需要标记地址
        submitData.saddrid = order.send.id
      }
      this.$http.post(url, {
        data:submitData
      }).then(result => {
        this.loading.hide()
        this.order.ordering = false
        this.$toast('下单成功', 1000)
        let data = result.data

        util.setStorage("kd_sentcom", this.order.company)
        util.setStorage("kd_servicetype", this.order.servicetype)
        this.order.department && (util.setStorage("kd_department", this.order.department))
        this.addCustomCargo()
        this.navigator.push({
          name: "normalDetail",
          query: {
            expid:  data.data ? result.data.data[0].expId : data[0].expId,
            sign: this.marketData.sign
          }
        })
      }).catch(resultJson => {
        this.loading.hide()
        this.order.ordering = false
        if(resultJson.message.indexOf('暂停接单') != -1){
          this.$confirm({
            content:'抱歉，金蝶软件园店正在进行系统升级，停止接单。点击确定查找附近快递员下单'
          }, () => {
            this.goCourier()
          })
        }
      })
    },
    showCargo(){
      this.cargoTp = {
        cargo: this.order.cargo,
        count:this.order.count,
        weight: this.order.weight
      }
      this.showPopup("cargo")
    },
    addCustomCargo(){
      var cargos = util.getStorage('customCargo') || []
      var val = this.order.cargo
      cargos.indexOf(val) == -1 && this.cargos.normal.indexOf(val) == -1 && cargos.push(val)
      util.setStorage('customCargo', cargos.slice(-5))
    },
    confirmCargo() {
      if(!this.cargoTp.cargo) {
        return this.$toast("物品名称不能为空",1500)
      }
      if(this.cargoTp.weight != "" && !+this.cargoTp.weight) {
        return this.$toast("请填写正确的物品重量",1500)
      }
      if(this.cargoTp.count != "" && !+this.cargoTp.count) {
        return this.$toast("物品数量必须大于0",1500)
      }
      this.order.cargo = this.cargoTp.cargo
      this.order.count = this.cargoTp.count
      this.order.weight = this.cargoTp.weight
      history.back()
    },
    catComment(text) {
      if(this.order.comment.indexOf(text) == -1) {
        this.order.comment += ' ' + text
      } else {
        this.order.comment = this.order.comment.replace(text, '').replace('  ',' ')
      }
    },
    // getAuth(callback){
    //   this.$http.get("/apicenter/order.do?method=queryUserCardInfo", {
         
    //   }).then(result => {
    //     if(result.data) {
    //       this.auth = true
    //     } else {
    //       this.auth = false
    //     }
    //     typeof callback == 'function' && callback()
    //   })
    // },
    // goAuth() {
    //   this.doAuth = true
    //   if(this.islogin) {
    //     this.navigator.push({
    //       name: "auth"
    //     })
    //   } else {
    //     this.navigator.push({
    //       name: "login"
    //     })
    //   }
    // },
    loginCallback() {
      this.islogin = true
      // if (!this.doAuth) {
      //   this.getAuth()
      //   return
      // }
      // this.doAuth = false
      // if (this.login2submit) {
      //   this.submit()
      // } else {
      //   this.getAuth(() =>{
      //     if (this.auth) {
      //       this.$toast("该账户已实名登记", 1500)
      //       this.authCallback()
      //     } else {
      //       this.goAuth()
      //     }
      //   })
      // }
    },
    // authCallback() {
    //   this.auth = true
    //   if (this.waitSubmit) {
    //     this.submit()
    //   }
    // },
    initRouteState() {
      history.replaceState({title:'orderInfo'}, "")
      window.addEventListener("popstate",  (e) => {
        if (history.state) {
          var currentState = e.state
          switch (currentState.title) {
            case ("cargo"):
            case("comlist"):
            case("comlist2"):
            case("valins"):
              break
            case("orderInfo"):
            default:
              this.popup.show = false
              this.popup.type = ''
              if(!this.order.payaccount && this.order.payment == 'MONTHLY') {
                this.order.payment = this.selectCom.payway != 'CONSIGNEE' ? 'SHIPPER' : 'CONSIGNEE'
              }
              if(this.order.valins.check == 'valin' && (!/^\d+(?:\.\d+)?$/.test(this.order.valins.value) || this.order.valins.value > 100000)) {
                this.order.valins.check = 'novalin'
                this.order.valins.value = ''
              }
          }
        }
      })
    }
  },
  computed: {
    sendman: function() {
      return this.order.send.name ? this.order.send.name + " " + util.hidePhone(this.order.send.phone || this.order.send.fixedPhone || '') : ''
    },
    recman: function() {
      return this.order.rec.name ? this.order.rec.name + " " + util.hidePhone(this.order.rec.phone || this.order.rec.fixedPhone || '') : ''
    },
    cargoStr: function(){
      var c = this.order.cargo
      if(!c) return ''
      this.order.weight && (c += ' ' + this.order.weight + 'kg')
      this.order.count && (c += ' ' + this.order.count + '件')
      return c;
    },
    isShenzhen: function() {
      return this.order.send.xzqName.indexOf("深圳") != -1
    },
    codeSign: function() {
      return encodeURIComponent(`sign=${this.marketData.sign}`)
    }
  },
  watch: {
    showHeavy: function(val) {
      !val && util.setStorage("showHeavy", 1)
    },
    'order.sentunit.value': function(val) {
      if (val == "PERSONAL" && this.order.payment == 'MONTHLY') {
        this.order.payment = 'SHIPPER'
      }
      if(val == "COMPANY") {
        this.order.payment = 'MONTHLY'
      }
    },
    'cargoTp.cargo': function(val) {
      if(val.length > 20) {
        this.$toast("物品名称不能超过20个字",1500)
        this.cargoTp.cargo = val.slice(0,20)
      }
    },
    'cargoTp.count': function(val) {
      if(val == '') return
      // val = ('' + val).replace(/\D/,'')
      clearTimeout(this.timer_big)
      this.timer_big = setTimeout(()=> { 
        val = String(val).replace(/\D/g, '');
        if(+val > 999) {
          this.$toast("物品数量不能超过999",1500)
          this.cargoTp.count = '999'
        } else {
          this.cargoTp.count = +val || ''
        }
      }, 200)
    },
    'cargoTp.weight': function(val) {
      if(/^\d+\.\d?$/.test(val)) return
      val = parseFloat(val)
      if(isNaN(val)) {
        this.cargoTp.weight = ''
      } else if (val > 30) {
        this.cargoTp.weight = 30
        this.$toast("物品重量不能超过30kg",1500)
      } else {
        this.cargoTp.weight = +val.toFixed(1)
      }
    }
  },
  created: function() {
    console.log('2022-05-20')
    let sign = util.query("sign")
    if (!sign) {
      this.goCourier()
    } else {
      this.curentLat = util.query("lat") || ""
      this.currentLng = util.query("lng") || ""
      this.login(() => {
        this.islogin = true
        this.getMarketBySign(sign)
        // !this.auth && this.getAuth()
      }, () => {
        this.getMarketBySign(sign)
      })
    }
    if(util.query("heavy") == 1) {
      //this.getGoods("BIG_ITEMS")
    }
    this.initRouteState()
  },
  beforeRouteEnter(to, from, next) {
    if(to.query.heavy == '1') {
      document.title = "寄大件"
    } else {
      document.title = "寄件服务"
    }
    next(vm => {
      if(from.name == 'login' && vm.$store.state.globalData.token) {
        vm.loginCallback()
      }
      // if(from.name == 'auth' && vm.$store.state.userAuth ) {
      //   vm.authCallback()
      // }
    })
  },
  beforeDestroy() {
    this.unactConfirm && this.unactConfirm()
  }
}